var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('b-row',{staticClass:"loading-row"},[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('span',{staticClass:"spinner-border spinner-border text-primary"})])])],1):_vm._e(),(!_vm.loading)?_c('b-row',{staticClass:"justify-content-center mt-5"},[_c('b-col',{attrs:{"xl":"8","lg":"10","cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('PulsCardHeader',{attrs:{"title":_vm.$t("integrations.setupIntegration.title", {
              appName: _vm.$t(("integrations.apps." + _vm.app + ".title"))
            }),"leftAction":{
            to: {
              name: 'Integrations',
              title: _vm.$t('integrations.allIntegrations')
            }
          }}}),_c('b-card-body',[(_vm.error)?_c('b-alert',{attrs:{"show":true,"variant":"danger"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"bi bi-exclamation-octagon-fill text-danger fs-3 me-3"}),_c('span',[_vm._v(" "+_vm._s(_vm.error)+" ")])])]):_vm._e(),(!_vm.loading && !_vm.error)?_c('div',[(_vm.validIncomingIntegration)?_c('div',[_c('div',{staticClass:"select-group"},[_c('PulsFormSelect',{staticClass:"w-lg-50",attrs:{"options":_vm.groupOptions,"label":_vm.$t('integrations.groups.title', {
                      appName: _vm.$t(("integrations.apps." + _vm.app + ".title"))
                    })},model:{value:(_vm.selectedGroupName),callback:function ($$v) {_vm.selectedGroupName=$$v},expression:"selectedGroupName"}})],1),(_vm.selectedGroupName)?_c('div',{staticClass:"select-event"},[_c('PulsFormSelect',{staticClass:"w-lg-50 mt-10",attrs:{"options":_vm.eventOptions,"label":_vm.$t('integrations.events.title', {
                      appName: _vm.$t(("integrations.apps." + _vm.app + ".title"))
                    })},model:{value:(_vm.selectedEventName),callback:function ($$v) {_vm.selectedEventName=$$v},expression:"selectedEventName"}}),(_vm.selectedEventName)?_c('div',{staticClass:"mt-7 d-flex align-items-center text-info"},[_c('i',{staticClass:"bi bi-info-circle-fill me-3 text-info"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t( ("integrations.events." + _vm.selectedEventName + ".description"), { groupTitle: _vm.groupTitleForGroupName(_vm.selectedGroupName) } ))+" ")])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end mt-5"},[_c('b-button',{staticClass:"btn-round",attrs:{"type":"submit","variant":"primary","disabled":!_vm.validForm},on:{"click":_vm.didClickSave}},[_vm._v(" "+_vm._s(_vm.$t('integrations.setupIntegration.saveButtonTitle'))+" ")])],1)]):_c('div',{staticClass:"m-10"},[_c('b-alert',{attrs:{"show":true,"variant":"danger"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"bi bi-exclamation-octagon-fill text-danger fs-3 me-3"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('integrations.errors.invalidIncomingIntegration'))+" ")])])])],1)]):_vm._e()],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }