<template>
  <b-container fluid>
    <b-row v-if="loading" class="loading-row">
      <b-col>
        <div class="d-flex justify-content-center">
          <span class="spinner-border spinner-border text-primary"></span>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!loading" class="justify-content-center mt-5">
      <b-col xl="8" lg="10" cols="12">
        <b-card no-body>
          <PulsCardHeader
            :title="
              $t(`integrations.setupIntegration.title`, {
                appName: $t(`integrations.apps.${app}.title`)
              })
            "
            :leftAction="{
              to: {
                name: 'Integrations',
                title: $t('integrations.allIntegrations')
              }
            }"
          />
          <b-card-body>
            <b-alert v-if="error" :show="true" class="" variant="danger">
              <div class="d-flex align-items-center">
                <i
                  class="bi bi-exclamation-octagon-fill text-danger fs-3 me-3"
                ></i>
                <span>
                  {{ error }}
                </span>
              </div>
            </b-alert>

            <div v-if="!loading && !error">
              <div v-if="validIncomingIntegration">
                <div class="select-group">
                  <PulsFormSelect
                    v-model="selectedGroupName"
                    :options="groupOptions"
                    :label="
                      $t('integrations.groups.title', {
                        appName: $t(`integrations.apps.${app}.title`)
                      })
                    "
                    class="w-lg-50"
                  />
                </div>
                <div v-if="selectedGroupName" class="select-event">
                  <PulsFormSelect
                    v-model="selectedEventName"
                    :options="eventOptions"
                    :label="
                      $t('integrations.events.title', {
                        appName: $t(`integrations.apps.${app}.title`)
                      })
                    "
                    class="w-lg-50 mt-10"
                  />
                  <div
                    v-if="selectedEventName"
                    class="mt-7 d-flex align-items-center text-info"
                  >
                    <i class="bi bi-info-circle-fill me-3 text-info"></i>
                    <span>
                      {{
                        $t(
                          `integrations.events.${selectedEventName}.description`,
                          {
                            groupTitle:
                              groupTitleForGroupName(selectedGroupName)
                          }
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-5">
                  <b-button
                    type="submit"
                    @click="didClickSave"
                    variant="primary"
                    class="btn-round"
                    :disabled="!validForm"
                  >
                    {{ $t('integrations.setupIntegration.saveButtonTitle') }}
                  </b-button>
                </div>
              </div>
              <div v-else class="m-10">
                <b-alert :show="true" class="" variant="danger">
                  <div class="d-flex align-items-center">
                    <i
                      class="bi bi-exclamation-octagon-fill text-danger fs-3 me-3"
                    ></i>
                    <span>
                      {{ $t('integrations.errors.invalidIncomingIntegration') }}
                    </span>
                  </div>
                </b-alert>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable no-console */

/*

https://slack.com/oauth/v2/authorize?client_id=416200280944.2239525256932&redirect_uri=https://www.puls-solutions.com/integration_callback/index.html?app=slack&scope=incoming-webhook&user_scope=
*/
import {
  PulsFormSelect,
  PulsCardHeader
} from '@puls-solutions/puls-ui-components'
export default {
  name: 'Integration',
  components: { PulsFormSelect, PulsCardHeader },
  data() {
    return {
      selectedGroupName: null,
      selectedEventName: null
    }
  },
  computed: {
    integrations() {
      return this.$store.getters.integrations || []
    },
    groupsAvailable() {
      return this.$store.getters.integrationsAvailable.groupsAvailable || []
    },
    eventsAvailable() {
      return this.$store.getters.integrationsAvailable.eventsAvailable || []
    },
    groupOptions() {
      return [
        { value: null, title: this.$t('integrations.nothingSelected') }
      ].concat(
        this.groupsAvailable.map((g) => {
          return {
            value: g.name,
            title: this.groupTitleForGroupName(g.name)
          }
        })
      )
    },
    eventOptions() {
      return [
        { value: null, text: this.$t('integrations.nothingSelected') }
      ].concat(
        this.eventsAvailable.map((e) => {
          return {
            value: e.name,
            text: this.$t(`integrations.events.${e.name}.title`)
          }
        })
      )
    },
    app() {
      return this.$route.query.app
    },
    code() {
      return this.$route.query.code
    },
    integrationsError() {
      return this.$store.getters.integrationsError
    },
    error() {
      return this.$route.query.error || this.integrationsError
    },
    loading() {
      return this.$store.getters.integrationsLoading
    },
    details() {
      return {
        groupName: this.selectedGroupName,
        locale: 'en',
        enabled: true,
        events: [this.selectedEventName]
      }
    },
    validIncomingIntegration() {
      return this.code && this.app && !this.error
    },
    validForm() {
      return this.selectedGroupName && this.selectedEventName
    }
  },
  watch: {
    integrations(newIntegrations) {
      // since store will be empty when loading this
      this.$router.push({
        name: 'Integrations',
        params: { addIntegrationSuccess: true }
      })
    }
  },
  methods: {
    groupTitleForGroupName(groupName) {
      const foundGroups = this.groupsAvailable.filter(
        (g) => g.name === groupName
      )
      if (foundGroups.length) {
        return this.$tr(foundGroups[0].details.messages, 'title')
      } else {
        return '?' // if group is not found, we must fail during event handling
      }
    },
    didClickSave() {
      this.addIntegration()
    },
    addIntegration() {
      this.$store.dispatch('saveIntegration', {
        appName: this.app,
        code: this.code,
        details: this.details
      })
    }
  },
  mounted() {
    this.$store.dispatch('loadIntegrationsAvailable')
    if (!this.error) {
      // this.addIntegration()
    }
  }
}
</script>
<style type="scss" scoped></style>
